var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-fade-in" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "goods-specs-mask" },
          [
            _c("transition", { attrs: { name: "modal" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "goods-specs",
                  attrs: {
                    "element-loading-text": "正在提交中",
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(255, 255, 255, 0.3)"
                  }
                },
                [
                  _c("div", { staticClass: "goods-specs-hader" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.showTitle))]),
                    _c("i", {
                      staticClass: "iconfont icondelete1",
                      on: {
                        click: function($event) {
                          return _vm.$emit("on-cancel")
                        }
                      }
                    })
                  ]),
                  _c("hr", { staticClass: "hr" }),
                  _vm.sureData.type == "transfer"
                    ? _c(
                        "div",
                        { staticClass: "showItem showItem-transfer" },
                        [
                          _c(
                            "el-form",
                            { attrs: { model: _vm.form } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: { label: "学员账号" }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.sureData.stuMobile)
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: {
                                            label: "学员姓名",
                                            prop: "a"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.sureData.name))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: {
                                            label: "身份证号",
                                            prop: "a"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.sureData.idCard))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticClass: "border" }),
                              _vm.showCjSingleOption
                                ? [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "orderItem",
                                        attrs: { label: "转班商品", prop: "a" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(_vm.sureData.goodsName))
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "size",
                                            staticStyle: { "margin-left": "0" }
                                          },
                                          [_vm._v(_vm._s(_vm.sureData.spec))]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "p",
                                            { staticClass: "litter-title" },
                                            [_vm._v("应收金额")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("decimal2")(
                                                  _vm.sureData.amountReceivable
                                                )
                                              ) + " 元"
                                            )
                                          ])
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "p",
                                            { staticClass: "litter-title" },
                                            [_vm._v("转班抵扣")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                color: "#F54955",
                                                "font-weight": "bold"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("decimal2")(
                                                    _vm.sureData
                                                      .approvedRefundableAmount
                                                  )
                                                ) + " 元"
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "p",
                                            { staticClass: "litter-title" },
                                            [_vm._v("待收金额")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("decimal2")(
                                                  _vm.sureData.waitpayAmount
                                                )
                                              ) + " 元"
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "报考院校" }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sureData.currentGoods
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "报考专业" }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sureData
                                                        .enrollingMajorNameList
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "报考学级" }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sureData.enrollingTime
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "border" }),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "orderItem",
                                        attrs: { label: "报名商品" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.sureData.goodsName))
                                        ]),
                                        _c("span", { staticClass: "size" }, [
                                          _vm._v(_vm._s(_vm.sureData.specName))
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "orderItem",
                                        attrs: { label: "转班商品", prop: "a" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.sureData.goodsName))
                                        ]),
                                        _c("span", { staticClass: "size" }, [
                                          _vm._v(_vm._s(_vm.sureData.specName))
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "size",
                                            staticStyle: { "margin-left": "0" }
                                          },
                                          [_vm._v(_vm._s(_vm.sureData.spec))]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "p",
                                            { staticClass: "litter-title" },
                                            [_vm._v("应收金额")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("decimal2")(
                                                  _vm.sureData.amountReceivable
                                                )
                                              ) + " 元"
                                            )
                                          ])
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "p",
                                            { staticClass: "litter-title" },
                                            [_vm._v("转班抵扣")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                color: "#F54955",
                                                "font-weight": "bold"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("decimal2")(
                                                    _vm.sureData
                                                      .approvedRefundableAmount
                                                  )
                                                ) + " 元"
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "p",
                                            { staticClass: "litter-title" },
                                            [_vm._v("待收金额")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("decimal2")(
                                                  _vm.sureData.waitpayAmount
                                                )
                                              ) + " 元"
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                              _c(
                                "el-row",
                                { staticStyle: { margin: "30px 0 20px 0" } },
                                [
                                  _vm.sureData.isLoanRefund &&
                                  _vm.sureData.refundMoney > 0
                                    ? _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "p",
                                          { staticClass: "litter-title" },
                                          [_vm._v("贷款退费")]
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("decimal2")(
                                                _vm.sureData.amountReceivable
                                              )
                                            ) + " 元"
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("p", { staticClass: "litter-title" }, [
                                      _vm._v("学员退费")
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("decimal2")(
                                            _vm.sureData.refundMoney
                                          )
                                        ) + " 元"
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm.sureData.type == "upgrade"
                    ? _c(
                        "div",
                        { staticClass: "showItem" },
                        [
                          _c(
                            "el-form",
                            { attrs: { model: _vm.form } },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: {
                                            label: "学习账号",
                                            prop: "a"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.sureData.stuMobile)
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: {
                                            label: "学员姓名",
                                            prop: "a"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.sureData.name))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _vm.sureData.type == "transfer" ||
                                      _vm.sureData.type == "upgrade"
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "身份证号",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.idCard)
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.sureData.type == "upgrade"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "orderItem",
                                      attrs: { label: "升班商品", prop: "a" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.sureData.goodsName))
                                      ]),
                                      _c("p", { staticClass: "size" }, [
                                        _vm._v(_vm._s(_vm.sureData.spec))
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.sureData.type == "upgrade"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "升班优惠" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("decimal2")(
                                              _vm.sureData.upgradeDiscountPrice
                                            )
                                          ) +
                                          " 元 "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.sureData.type == "transfer" ||
                              _vm.sureData.type == "upgrade"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "orderItem",
                                      attrs: { label: "应收金额", prop: "a" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "refundMoney" },
                                        [
                                          _vm.sureData.finalPrice < 0
                                            ? [_vm._v("0.00")]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("decimal2")(
                                                        _vm.sureData.finalPrice
                                                      )
                                                    ) +
                                                    " 元 "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.sureData.type == "dropOut"
                    ? _c(
                        "div",
                        { staticClass: "showItem" },
                        [
                          _c(
                            "el-form",
                            { attrs: { model: _vm.form } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: {
                                            label: "售后订单",
                                            prop: "orderNo"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.sureData.orderNo))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: {
                                            label: "已收金额",
                                            prop: "amountReceived"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("decimal2")(
                                                  _vm.sureData.amountReceived
                                                )
                                              ) + " 元"
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "orderItem",
                                  attrs: { label: "售后商品" }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.sureData.goodsName))
                                  ]),
                                  _c("p", { staticClass: "size" }, [
                                    _vm._v(_vm._s(_vm.sureData.spec))
                                  ])
                                ]
                              ),
                              _vm.sureData.isYQH
                                ? [
                                    _c("div", { staticClass: "border" }),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "贷款退费" }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "refundMoney"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("decimal2")(
                                                          _vm.sureData
                                                            .loanRefundMoney
                                                        )
                                                      ) + " 元"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "贷款平台" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.sureData
                                                        .loanPlatformName
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "退贷日期" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.sureData
                                                        .loanRefundTime
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "border" })
                                  ]
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "orderItem ",
                                  attrs: { label: "学员退费" }
                                },
                                [
                                  _c("span", { staticClass: "refundMoney" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("decimal2")(
                                          _vm.sureData.refundMoney
                                        )
                                      ) + " 元"
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "orderItem",
                                          attrs: { label: "退款方式" }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.sureData.payType))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.sureData.payTypeId ==
                                  _vm.refundPayTypeEnum.bank
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: { label: "开户名" }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData.bankAccountName
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.sureData.payTypeId ==
                              _vm.refundPayTypeEnum.bank
                                ? [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "开户行" }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.sureData.bank)
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "户行账号" }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sureData.bankAccount
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "学习账号" }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.sureData.mobile)
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "orderItem",
                                                attrs: { label: "学员姓名" }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.sureData.name)
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "showItem caijing",
                          staticStyle: {
                            "padding-top": "20px",
                            "padding-bottom": "20px"
                          }
                        },
                        [
                          _vm.showCkSingleOption
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form",
                                    { attrs: { model: _vm.form } },
                                    [
                                      _c(
                                        "el-row",
                                        { staticStyle: { padding: "5px 0" } },
                                        [
                                          _c("el-col", { attrs: { span: 6 } }, [
                                            _c(
                                              "p",
                                              { staticClass: "litter-title" },
                                              [_vm._v("学员账号")]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(_vm.sureData.stuMobile)
                                              )
                                            ])
                                          ]),
                                          _c("el-col", { attrs: { span: 7 } }, [
                                            _c(
                                              "p",
                                              { staticClass: "litter-title" },
                                              [_vm._v("学员姓名")]
                                            ),
                                            _c("p", [
                                              _vm._v(_vm._s(_vm.sureData.name))
                                            ])
                                          ]),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "6px"
                                              },
                                              attrs: { span: 11 }
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "litter-title" },
                                                [_vm._v("身份证号")]
                                              ),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.idCard)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: {
                                            padding: "5px 0",
                                            "margin-bottom": "1px solid #eee"
                                          }
                                        },
                                        [
                                          _c("el-col", { attrs: { span: 6 } }, [
                                            _c(
                                              "p",
                                              { staticClass: "litter-title" },
                                              [_vm._v("报考院校")]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.sureData
                                                    .enrollingSchoolName
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("el-col", { attrs: { span: 7 } }, [
                                            _c(
                                              "p",
                                              { staticClass: "litter-title" },
                                              [_vm._v("报考专业")]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.sureData.enrollingMajorId
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("el-col", { attrs: { span: 6 } }, [
                                            _c(
                                              "p",
                                              { staticClass: "litter-title" },
                                              [_vm._v("报考学级")]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.sureData
                                                    .enrollingGradationId
                                                )
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "1px",
                                          backgroundColor: "#eee"
                                        }
                                      }),
                                      _c(
                                        "el-row",
                                        { staticStyle: { padding: "5px 0" } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "litter-title" },
                                                [_vm._v("报名商品")]
                                              ),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.goodsName)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        { staticStyle: { padding: "5px 0" } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "litter-title" },
                                                [_vm._v("应收金额")]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "font-size": "24px",
                                                    color: "#F54955",
                                                    "font-weight": "bold"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.sureData
                                                          .goodsPrice > 0
                                                          ? _vm.sureData
                                                              .allGoodsPrice
                                                          : 0.0
                                                      ) +
                                                      " 元 "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showZkMultipleOption && _vm.isDoubleProfessional
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form",
                                    { attrs: { model: _vm.form } },
                                    [
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "学员账号",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.stuMobile)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "学员姓名",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.name)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "身份证号",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.idCard)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "专科专业",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData
                                                      .curEnrollingMajorName &&
                                                      _vm.sureData.curEnrollingMajorName.split(
                                                        "/"
                                                      )[0]
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报考省份",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData.zkProvinceId
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报考院校",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData.zkSchoolId
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "本科专业",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData
                                                      .curEnrollingMajorName &&
                                                      _vm.sureData.curEnrollingMajorName.split(
                                                        "/"
                                                      )[1]
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报考省份",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData.bkProvinceId
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报考院校",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData.bkSchoolId
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报名商品",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.goodsName)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "应收金额",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "￥" +
                                                    _vm._s(
                                                      _vm.sureData
                                                        .allGoodsPrice > 0
                                                        ? _vm.sureData
                                                            .allGoodsPrice
                                                        : 0
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("el-col")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showZkSingleOption ||
                          (_vm.showZkMultipleOption &&
                            !_vm.isDoubleProfessional)
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form",
                                    { attrs: { model: _vm.form } },
                                    [
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "学员账号",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.stuMobile)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "学员姓名",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.name)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "身份证号",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.idCard)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报考专业",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData
                                                      .enrollingMajorName
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报考省份",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData.provinceId
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报考院校",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.schoolId)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "报名商品",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.sureData.goodsName)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "应收金额",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "￥" +
                                                    _vm._s(
                                                      _vm.sureData.goodsPrice >
                                                        0
                                                        ? _vm.sureData
                                                            .allGoodsPrice
                                                        : 0
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("el-col"),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "待收金额",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "refundMoney" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sureData.finalPrice >
                                                        0
                                                        ? _vm.sureData
                                                            .finalPrice
                                                        : 0
                                                    ) + "元"
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "orderItem",
                                              attrs: {
                                                label: "贷款",
                                                prop: "a"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.sureData.isLoan > 0
                                                      ? "是"
                                                      : "否"
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showCjSingleOption
                            ? _c(
                                "el-row",
                                {
                                  staticClass: "caijing",
                                  staticStyle: { padding: "18px 0" }
                                },
                                [
                                  _c(
                                    "el-row",
                                    { staticStyle: { padding: "5px 0" } },
                                    [
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "p",
                                          { staticClass: "litter-title" },
                                          [_vm._v("学员账号")]
                                        ),
                                        _c("p", [
                                          _vm._v(_vm._s(_vm.sureData.stuMobile))
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "p",
                                          { staticClass: "litter-title" },
                                          [_vm._v("学员姓名")]
                                        ),
                                        _c("p", [
                                          _vm._v(_vm._s(_vm.sureData.name))
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "p",
                                          { staticClass: "litter-title" },
                                          [_vm._v("身份证号")]
                                        ),
                                        _c("p", [
                                          _vm._v(_vm._s(_vm.sureData.idCard))
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    { staticStyle: { padding: "5px 0" } },
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "p",
                                          { staticClass: "litter-title" },
                                          [_vm._v("报名商品")]
                                        ),
                                        _c("p", [
                                          _vm._v(_vm._s(_vm.sureData.goodsName))
                                        ]),
                                        _vm.sureData.goodsDetail
                                          ? _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  color: "#999",
                                                  "font-size": "12px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.sureData.goodsDetail
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    { staticStyle: { padding: "5px 0" } },
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "p",
                                          { staticClass: "litter-title" },
                                          [_vm._v("应收金额")]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "24px",
                                              color: "#F54955",
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.sureData.goodsPrice > 0
                                                    ? (+_vm.sureData
                                                        .allGoodsPrice).toFixed(
                                                        2
                                                      )
                                                    : 0.0
                                                ) +
                                                " 元 "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                  _c("hr", { staticClass: "hr" }),
                  _c(
                    "div",
                    { staticClass: "goods-specs-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { gap: 18 },
                          on: {
                            click: function($event) {
                              return _vm.$emit("on-cancel")
                            }
                          }
                        },
                        [_vm._v("返回修改")]
                      ),
                      _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.onOk } },
                        [_vm._v("确定提交")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }