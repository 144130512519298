<template>
  <transition name="el-fade-in">
    <div class="goods-specs-mask" v-if="visible">
      <transition name="modal">
        <div
          class="goods-specs"
          v-loading="loading"
          element-loading-text="正在提交中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.3)"
        >
          <div class="goods-specs-hader">
            <h1>{{ showTitle }}</h1>
            <i class="iconfont icondelete1" @click="$emit('on-cancel')"></i>
          </div>
          <hr class="hr" />
          <!-- 转班 -->
          <div class="showItem showItem-transfer" v-if="sureData.type == 'transfer'">
            <el-form :model="form">
              <el-row :gutter="24">
                <el-col :span="8">
                  <el-form-item class="orderItem" label="学员账号">
                    <span>{{ sureData.stuMobile }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item class="orderItem" label="学员姓名" prop="a">
                    <span>{{ sureData.name }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item class="orderItem" label="身份证号" prop="a">
                    <span>{{ sureData.idCard }}</span>
                  </el-form-item>
                </el-col>
              </el-row >
              <div class="border"></div>
              <!-- 财经转班 -->
              <template v-if="showCjSingleOption">
                <el-form-item class="orderItem" label="转班商品" prop="a">
                  <div>{{ sureData.goodsName }}</div>
                  <span class="size" style="margin-left: 0">{{ sureData.spec }}</span>
                </el-form-item>
                <el-row>
                  <el-col :span="8">
                    <p class="litter-title">应收金额</p>
                    <p>{{ sureData.amountReceivable | decimal2 }} 元</p>
                  </el-col>
                  <el-col :span="8">
                    <p class="litter-title">转班抵扣</p>
                    <p style="color: #F54955;font-weight: bold;">{{ sureData.approvedRefundableAmount | decimal2 }} 元</p>
                  </el-col>
                  <el-col :span="8">
                    <p class="litter-title">待收金额</p>
                    <p>{{ sureData.waitpayAmount | decimal2 }} 元</p>
                  </el-col>
                </el-row>
                <!-- <el-form-item class="orderItem" label="应收金额" prop="a">
                  <span class="refundMoney">{{ sureData.amountReceivable | decimal2 }} 元</span>
                </el-form-item>
                <el-form-item class="orderItem" label="转班抵扣" prop="a">
                  <span >{{ sureData.approvedRefundableAmount | decimal2 }} 元</span>
                </el-form-item> -->
              </template>
              <!-- 学历转班 -->
              <template v-else>
                <!-- <el-form-item class="orderItem" label="报考院校">
                  <span>{{ sureData.currentGoods }}</span>
                </el-form-item> -->
                <el-row>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="报考院校">
                      <span>{{ sureData.currentGoods }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="报考专业">
                      <span>{{ sureData.enrollingMajorNameList }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="报考学级">
                      <span>{{ sureData.enrollingTime }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="border"></div>
                <el-form-item class="orderItem" label="报名商品">
                  <span>{{ sureData.goodsName }}</span>
                  <span class="size">{{ sureData.specName }}</span>
                </el-form-item>
                <!-- <el-form-item class="orderItem" label="应收金额">
                  <span class="refundMoney">￥{{ sureData.amountReceivable }} 元</span>
                </el-form-item>
                <el-form-item class="orderItem" label="转班抵扣">
                  <span >{{ sureData.approvedRefundableAmount | decimal2 }} 元</span>
                </el-form-item> -->
                <el-form-item class="orderItem" label="转班商品" prop="a">
                  <span>{{ sureData.goodsName }}</span>
                  <span class="size">{{ sureData.specName }}</span>
                  <div class="size" style="margin-left: 0">{{ sureData.spec }}</div>
                </el-form-item>
                <el-row>
                  <el-col :span="8">
                    <p class="litter-title">应收金额</p>
                    <p>{{ sureData.amountReceivable | decimal2 }} 元</p>
                  </el-col>
                  <el-col :span="8">
                    <p class="litter-title">转班抵扣</p>
                    <p style="color: #F54955;font-weight: bold;">{{ sureData.approvedRefundableAmount | decimal2 }} 元</p>
                  </el-col>
                  <el-col :span="8">
                    <p class="litter-title">待收金额</p>
                    <p>{{ sureData.waitpayAmount | decimal2 }} 元</p>
                  </el-col>
                </el-row>
              </template>
              <!-- <el-form-item class="orderItem" label="待收金额">
                <span class="refundMoney">{{ sureData.waitpayAmount | decimal2 }} 元</span>
              </el-form-item> 
              <el-form-item class="orderItem" label="学员退费">
                <span class="refundMoney"><template v-if="sureData.refundMoney > 0">{{ sureData.refundMoney | decimal2 }}</template><template v-else>0.00</template> 元</span>
              </el-form-item> -->
              <!-- 高转低 -->
              <el-row style="margin: 30px 0 20px 0">
                <el-col :span="8" v-if="sureData.isLoanRefund && sureData.refundMoney > 0">
                  <p class="litter-title">贷款退费</p>
                  <p>{{ sureData.amountReceivable | decimal2 }} 元</p>
                </el-col>
                <el-col :span="8">
                  <p class="litter-title">学员退费</p>
                  <p>{{ sureData.refundMoney | decimal2 }} 元</p>
                </el-col>
              </el-row>
              <!-- <template v-if="sureData.refundMoney > 0">
                <el-row>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="退款方式" prop="a" >
                      <span>{{ sureData.payType }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="sureData.refundPayType == refundPayTypeEnum.bank">
                    <el-form-item class="orderItem" label="开户名">
                      <span>{{ sureData.bankAccountName }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-if="sureData.refundPayType == refundPayTypeEnum.bank">
                  <el-col :span="8" >
                    <el-form-item class="orderItem" label="开户行" prop="a" >
                      <span>{{ sureData.bank }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="户行账号">
                      <span>{{ sureData.bankAccount }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-else>
                  <el-col :span="8" >
                    <el-form-item class="orderItem" label="学习账号" prop="a" >
                      <span>{{ sureData.stuMobileNo }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="学员姓名">
                      <span>{{ sureData.name }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template> -->
            </el-form>
          </div>
          

          <!-- 升班 -->
          <div class="showItem" v-else-if="sureData.type == 'upgrade'">
            <el-form :model="form">
              <el-row :gutter="24">
                <el-col :span="8">
                  <el-form-item  class="orderItem" label="学习账号"  prop="a">
                    <span>{{ sureData.stuMobile }}</span> 
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="8">
                  <el-form-item class="orderItem" label="学员姓名" prop="a">
                    <span>{{ sureData.name }}</span>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item
                    class="orderItem"
                    v-if="
                      sureData.type == 'transfer' || sureData.type == 'upgrade'
                    "
                    label="身份证号"
                    prop="a"
                  >
                    <span>{{ sureData.idCard }}</span>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item class="orderItem" v-if="sureData.type == 'upgrade'" label="升班商品" prop="a">
                
                <span>{{ sureData.goodsName }}</span><p class="size">{{ sureData.spec }}</p>
              </el-form-item>
              <!-- <el-form-item
                class="orderItem"
                v-if="sureData.type == 'transfer'"
                label="余额抵扣"
                prop="a"
              >
                <div class="phoneCodeBox">
                  <span>￥{{ sureData.balancePay }}</span>
                  <el-input v-show="sureData.isBalancePay == 1" class="phoneCode" v-model="phoneCode" placeholder="授权码"    :disabled="sureData.mobile.length != 11"/>  
                  <el-button v-show="sureData.isBalancePay == 1" type="primary" :disabled="phoneCodeStatus || sureData.mobile.length != 11" round  class="codeBtn" @click="sendSmsCode">{{ phoneTipMsg }}</el-button  > 
                </div>
              </el-form-item> -->
              <el-form-item v-if="sureData.type == 'upgrade'" label="升班优惠">
                {{sureData.upgradeDiscountPrice | decimal2}} 元
              </el-form-item>
              <el-form-item  class="orderItem"  v-if="sureData.type == 'transfer' || sureData.type == 'upgrade'"  label="应收金额"  prop="a"><span class="refundMoney">
                <template v-if="sureData.finalPrice < 0">0.00</template>
                <template v-else>
                  {{ sureData.finalPrice | decimal2 }} 元
                </template>
                </span>
                </el-form-item>
            </el-form>
            <!-- <el-row :gutter="24">
              <el-col :span="6">
                <p class="litter-title">学员账号</p>
                <p>{{ sureData.stuMobile }}</p>
              </el-col>
              <el-col :span="7">
                <p class="litter-title">学员姓名</p>
                <p>{{ sureData.name }}</p>
              </el-col>
              <el-col :span="11">
                <p class="litter-title">身份证号</p>
                <p>{{ sureData.idCard }}</p>
              </el-col>
              <el-col :span="24">
                <p class="litter-title">售后订单</p>
                <p>{{ sureData.orderNo }}</p>
              </el-col>
              <el-col :span="24">
                <p class="litter-title">售后商品</p>
                <p>{{ sureData.oldGoodsName }}</p>
                <p class="litter-title" style="font-size: 12px">{{ sureData.oldSpecName }}</p>
              </el-col>
              <el-col :span="24">
                <p class="litter-title">升班商品</p>
                <p>{{ sureData.goodsName }}</p>
                <p class="litter-title" style="font-size: 12px">{{ sureData.spec }}</p>
              </el-col>
            </el-row> -->
          </div>

          <!-- 退费 -->
          <div class="showItem" v-else-if="sureData.type == 'dropOut'">
            <el-form :model="form">
              <el-row>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="售后订单" prop="orderNo"><span>{{ sureData.orderNo }}</span></el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item class="orderItem" label="已收金额" prop="amountReceived"><span>{{ sureData.amountReceived | decimal2 }} 元</span></el-form-item>
                </el-col>
              </el-row>
              <el-form-item class="orderItem" label="售后商品"><span>{{ sureData.goodsName }}</span><p class="size">{{ sureData.spec }}</p></el-form-item>
              <template v-if="sureData.isYQH">
                <div class="border"></div>
                <el-row>
                  <el-col :span="24">
                    <el-form-item class="orderItem" label="贷款退费">
                      <span class="refundMoney">{{ sureData.loanRefundMoney | decimal2 }} 元</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="贷款平台">
                      {{ sureData.loanPlatformName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="退贷日期">
                      {{ sureData.loanRefundTime }}
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="border"></div>
              </template>
              <el-form-item class="orderItem " label="学员退费"> <span class="refundMoney">{{ sureData.refundMoney | decimal2 }} 元</span> </el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="退款方式"><span>{{ sureData.payType }}</span></el-form-item>
                </el-col>
                <el-col :span="8" v-if="sureData.payTypeId == refundPayTypeEnum.bank">
                  <el-form-item class="orderItem" label="开户名"><span>{{ sureData.bankAccountName }}</span>  </el-form-item>
                </el-col>
              </el-row>
              <!-- 退费方式：银行账户 -->
              <template v-if="sureData.payTypeId == refundPayTypeEnum.bank">
                <el-row>
                  <el-col :span="12">
                    <el-form-item class="orderItem" label="开户行"><span>{{ sureData.bank }}</span></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="户行账号"><span>{{ sureData.bankAccount }}</span></el-form-item>
                  </el-col>
                </el-row>
              </template>
              <!-- 退费方式：余额 -->
              <template v-else>
                <el-row>
                  <el-col :span="12">
                    <el-form-item class="orderItem" label="学习账号"><span>{{ sureData.mobile }}</span></el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item class="orderItem" label="学员姓名"><span>{{ sureData.name }}</span></el-form-item>
                  </el-col>
                </el-row>
              </template>
              <!-- <el-form-item class="orderItem" label="学员姓名" prop="a"> <span>{{ sureData.name }}</span>  </el-form-item>
              <el-form-item class="orderItem" label="退款方式" prop="a"><span>{{ sureData.payType }}</span>   </el-form-item>
              <el-form-item class="orderItem " label="核定可退金额" prop="a" > <span class="refundMoney">￥{{ sureData.refundMoney | decimal2 }}</span> </el-form-item> -->
            </el-form>
          </div>

          <!-- 报名 -->
          <div
            class="showItem caijing"
            v-else
            style="padding-top: 20px; padding-bottom: 20px"
          >
            <!-- 成考 -->
            <el-row v-if="showCkSingleOption">
              <el-form :model="form">
                <el-row style="padding:5px 0">
                  <el-col :span="6">
                    <p class="litter-title">学员账号</p>
                    <p>{{ sureData.stuMobile }}</p>
                  </el-col>
                  <el-col :span="7">
                    <p class="litter-title">学员姓名</p>
                    <p>{{ sureData.name }}</p>
                  </el-col>
                  <el-col :span="11" style="margin-bottom: 6px">
                    <p class="litter-title">身份证号</p>
                    <p>{{ sureData.idCard }}</p>
                  </el-col>
                </el-row>
               
                <el-row style="padding:5px 0;margin-bottom:1px solid #eee">
                  <el-col :span="6">
                    <p class="litter-title">报考院校</p>
                    <p>{{ sureData.enrollingSchoolName }}</p>
                  </el-col>
                  <el-col :span="7">
                    <p class="litter-title">报考专业</p>
                    <p>{{ sureData.enrollingMajorId }}</p>
                  </el-col>
                  <el-col :span="6">
                    <p class="litter-title">报考学级</p>
                    <p>{{ sureData.enrollingGradationId }}</p>
                  </el-col>
                </el-row>
                 <div style="width:100%;height:1px ;backgroundColor:#eee;"></div>
                <el-row style="padding:5px 0">
                  <el-col :span="24">
                    <p class="litter-title">报名商品</p>
                    <p>{{ sureData.goodsName }}</p>
                  </el-col>
                </el-row>
                <el-row style="padding:5px 0">
                  <el-col :span="24">
                    <p class="litter-title">应收金额</p>
                    <p style="font-size:24px;color:#F54955;font-weight:bold">
                      {{ sureData.goodsPrice > 0 ? sureData.allGoodsPrice : 0.00 }} 元
                    </p>
                  </el-col>
                </el-row>

              </el-form>
            </el-row>

            <!-- 自考-双层次 -->
            <el-row v-if="showZkMultipleOption && isDoubleProfessional">
              <el-form :model="form">
                <el-col>
                  <el-form-item class="orderItem" label="学员账号" prop="a">
                    <span>{{ sureData.stuMobile }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="学员姓名" prop="a">
                    <span>{{ sureData.name }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="身份证号" prop="a">
                    <span>{{ sureData.idCard }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="专科专业" prop="a">
                    <span>{{
                      sureData.curEnrollingMajorName &&
                      sureData.curEnrollingMajorName.split("/")[0]
                    }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="报考省份" prop="a">
                    <span>{{ sureData.zkProvinceId }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="报考院校" prop="a">
                    <span>{{ sureData.zkSchoolId }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="本科专业" prop="a">
                    <span>{{
                      sureData.curEnrollingMajorName &&
                      sureData.curEnrollingMajorName.split("/")[1]
                    }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="报考省份" prop="a">
                    <span>{{ sureData.bkProvinceId }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="报考院校" prop="a">
                    <span>{{ sureData.bkSchoolId }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="报名商品" prop="a">
                    <span>{{ sureData.goodsName }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="应收金额" prop="a">
                    <span
                      >￥{{
                        sureData.allGoodsPrice > 0 ? sureData.allGoodsPrice : 0
                      }}</span
                    >
                  </el-form-item>
                </el-col>
                <el-col>
                  <!-- <el-form-item
                    class="orderItem"
                    label="余额抵扣"
                    prop="a"
                    v-if="sureData.isBalancePay == 1"
                  >
                    <div class="phoneCodeBox">
                      <span>{{ sureData.balancePay }}</span>
                      <el-input
                        v-show="sureData.isBalancePay == 1"
                        class="phoneCode"
                        v-model="phoneCode"
                        placeholder="授权码"
                        :disabled="sureData.mobile.length != 11"
                      />
                      <el-button
                        v-show="sureData.isBalancePay == 1"
                        type="primary"
                        :disabled="
                          phoneCodeStatus || sureData.mobile.length != 11
                        "
                        round
                        class="codeBtn"
                        @click="sendSmsCode"
                      >
                        {{ phoneTipMsg }}</el-button
                      >
                    </div>
                    <div></div>
                  </el-form-item> -->
                </el-col>
              </el-form>
            </el-row>

            <!-- 自考-单层次 -->
            <el-row
              v-if="
                showZkSingleOption ||
                (showZkMultipleOption && !isDoubleProfessional)
              "
            >
              <el-form :model="form">
                <el-col>
                  <el-form-item class="orderItem" label="学员账号" prop="a">
                    <span>{{ sureData.stuMobile }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="学员姓名" prop="a">
                    <span>{{ sureData.name }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="身份证号" prop="a">
                    <span>{{ sureData.idCard }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="报考专业" prop="a">
                    <span>{{ sureData.enrollingMajorName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="报考省份" prop="a">
                    <span>{{ sureData.provinceId }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="报考院校" prop="a">
                    <span>{{ sureData.schoolId }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="报名商品" prop="a">
                    <span>{{ sureData.goodsName }}</span>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item class="orderItem" label="应收金额" prop="a">
                    <span
                      >￥{{
                        sureData.goodsPrice > 0 ? sureData.allGoodsPrice : 0
                      }}</span
                    >
                  </el-form-item>
                </el-col>
                <el-col>
                  <!-- <el-form-item
                    class="orderItem"
                    label="余额抵扣"
                    prop="a"
                    v-if="sureData.isBalancePay == 1"
                  >
                    <div class="phoneCodeBox">
                      <span>{{ sureData.balancePay }}</span>
                      <el-input
                        v-show="sureData.isBalancePay == 1"
                        class="phoneCode"
                        v-model="phoneCode"
                        placeholder="授权码"
                        :disabled="sureData.mobile.length != 11"
                      />
                      <el-button
                        v-show="sureData.isBalancePay == 1"
                        type="primary"
                        :disabled="
                          phoneCodeStatus || sureData.mobile.length != 11
                        "
                        round
                        class="codeBtn"
                        @click="sendSmsCode"
                      >
                        {{ phoneTipMsg }}</el-button
                      >
                    </div>
                    <div></div>
                  </el-form-item> -->
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="待收金额" prop="a">
                    <span class="refundMoney"
                      >{{
                        sureData.finalPrice > 0 ? sureData.finalPrice : 0
                      }}元</span
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item class="orderItem" label="贷款" prop="a">
                    <span>{{ sureData.isLoan > 0 ? "是" : "否" }}</span>
                    <!-- <span class="size">{{ sureData.spec }}</span> -->
                  </el-form-item>
                </el-col>
              </el-form>
            </el-row>

            <!-- 财经 -->
            <el-row
              v-if="showCjSingleOption"
              class="caijing"
              style="padding: 18px 0"
            >
              <el-row style="padding:5px 0">
                <el-col :span="6">
                  <p class="litter-title">学员账号</p>
                  <p>{{ sureData.stuMobile }}</p>
                </el-col>
                <el-col :span="6">
                  <p class="litter-title">学员姓名</p>
                  <p>{{ sureData.name }}</p>
                </el-col>
                <el-col :span="6">
                  <p class="litter-title">身份证号</p>
                  <p>{{ sureData.idCard }}</p>
                </el-col>
              </el-row>
              <el-row style="padding:5px 0">
                <el-col :span="24">
                  <p class="litter-title">报名商品</p>
                  <p>{{ sureData.goodsName }}</p>
                  <p
                    v-if="sureData.goodsDetail"
                    style="color: #999; font-size: 12px"
                  >
                    {{ sureData.goodsDetail }}
                  </p>
                </el-col>
              </el-row>
              <el-row style="padding:5px 0">
              <el-col :span="24">
                <p class="litter-title">应收金额</p>
                <p style="font-size:24px;color:#F54955;font-weight:bold">
                  {{ sureData.goodsPrice > 0 ? (+sureData.allGoodsPrice).toFixed(2) : 0.00 }} 元
                </p>
              </el-col>
              </el-row>
            </el-row>
          </div>
          <hr class="hr" />

          <div class="goods-specs-footer">
            <el-button :gap="18" @click="$emit('on-cancel')"
              >返回修改</el-button
            >
            <el-button type="primary" @click="onOk">确定提交</el-button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { refundPayTypeEnum } from '@/views/order/constants'
// import CheckBox from "components/check-box";
export default {
  name: "GoodsSpecsModal",
  components: {
    // CheckBox
  },
  data() {
    return {
      phoneCode: "",
      phoneTipMsg: "获取授权码",
      phoneCodeStatus: false,
      smsCodeInterval: 60,
      form: {
        n1: "nnnn1",
      },
      sureData:[],
      showZkSingleOption:false,
      showCkSingleOption:false,
      showZkMultipleOption:false,
      isDoubleProfessional:false,
      showCjSingleOption:false,
      refundPayTypeEnum,
    }
  },
  props: {
    visible: Boolean,
    loading: Boolean,
    // sureData: [Array, Object],
    showTitle: String,
    // loading: Boolean
  },
  mounted() {},
  methods: {
    async sendSmsCode() {
      if (
        this.sureData.mobile.length < 11 ||
        !this.sureData.mobile.startsWith(1)
      ) {
        return this.$message.warning("手机账号格式不正确");
      }
      let params = {
        phone: this.sureData.mobile,
        money: this.sureData.balancePay,
      };
      const res = this.$fetch("sendAliSms", params);
      console.log("获取授权码的结果", res);
      this.phoneCodeStatus = true;
      this.phoneTipMsg = this.smsCodeInterval + "秒后重发";
      this.smsCodeTimer = setInterval(() => {
        if (this.smsCodeInterval <= 1) {
          clearInterval(this.smsCodeTimer);
          this.phoneTipMsg = "获取授权码";
          this.phoneCodeStatus = false;
          this.smsCodeInterval = 60;
          return;
        }
        this.smsCodeInterval--;
        this.phoneTipMsg = this.smsCodeInterval + "秒后重发";
      }, 1000);
    },
    // 选择规格确定
    onOk() {
      if (this.sureData.type === "dropOut") {
        this.$emit("on-ok");
        return;
      }

      const infoData = {
        phoneCode: this.phoneCode,
      };
      if (!this.phoneCode && this.sureData && this.sureData.balancePay > 0) {
        return this.$message.warning("请填写授权码");
      }
      this.$emit("on-ok", infoData);
    },
  },
  watch: {
    visible( newValue ){
      if( newValue ){
        try {
          this.sureData = JSON.parse(localStorage.getItem("sureData"));
          this.showZkSingleOption = eval(localStorage.getItem("showZkSingleOption"));
          this.showCkSingleOption = eval(localStorage.getItem("showCkSingleOption"));
          this.showZkMultipleOption = eval(localStorage.getItem("showZkMultipleOption"));
          this.showCjSingleOption = eval(localStorage.getItem("showCjSingleOption"));
          //是否是双专业
          this.isDoubleProfessional = eval(localStorage.getItem("isDoubleProfessional"));
        } catch (error) {
          console.error('[sure1.vue]', error);
        }
      }
    },

    currentGoodsRank(rank) {
      /**
       *
       *
       * 1、单品-默认规格
        2、单品-多规格
        3、套餐-默认规格
        4、套餐-多规格单选
        5、套餐-多规格多选
       *
       */
      console.log("rank :>> ", rank);
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/css/variable.scss";
.hr {
  margin: 0;
  padding: 0;
  height: 0.2px;
  border: 0.2px solid #e1e4eb;
}
::v-deep .el-form-item__label {
  width: 100px;
  text-align: left;
  margin-right: 0;
  float: none;
  padding: 0;

  font-size: 14px;
  color: #999999;
}
::v-deep .el-form-item__content {
  line-height: normal;
  color: #333333;
}
.showItem {
  padding: 0 30px;
  max-height: 70vh;
  overflow: auto;
  .orderItem {
    margin-bottom: 8px;
    .size {
      margin-left: 10px;
      font-size: 10px;
      color: #999999;
    }
    .refundMoney {
      color: #f54955;
      font-size: 18px;
      font-weight: bold;
    }
    .oldGoodsPrice {
      color: #f54955;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.phoneCodeBox {
  display: flex;
  align-items: center;
  .phoneCode {
    margin-left: 20px;
    width: 120px;
  }
  .codeBtn {
    background: #fff;
    color: #333;
    margin-left: 10px;
    color: #337aff;
    border: 1px solid #337aff;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    padding: 0 22px;
  }
}
.goods-specs-mask {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  .goods-specs {
    width: 676px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 12px 24px 0px rgba(112, 112, 112, 0.24);

    &-hader {
      position: relative;
      width: 100%;
      height: 63px;
      line-height: 63px;
      padding-left: 30px;

      > h1 {
        font-size: 16px;
        font-weight: 700;
        color: #333;
      }
      > i {
        position: absolute;
        top: 0;
        right: 24px;
        font-size: 14px;
        color: #9bacbf;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
    .goods-specs-body {
      width: 100%;
      padding: 30px 24px;
      border-top: 1px solid #e1e4eb;
      border-bottom: 1px solid #e1e4eb;
      .hr {
        width: 616px;
        margin: 0 auto;
        margin-bottom: 30px;
        border-bottom: 1px dashed #e0e0e0;
      }
      ::v-deep .already-select {
        display: flex;
        margin-bottom: 18px;
        > h3 {
          margin-right: 24px;
          font-size: 14px;
          color: #999;
        }
        .select-list {
          .select-item {
            font-size: 14px;
            color: #333;
            margin-bottom: 12px;
          }
        }
        .signed {
          margin-left: 10px;
          color: #f54955;
          font-size: 12px;
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 84px;
    }
  }
}
.single-default {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337aff;
    background: #ffffff;
    border: 1px solid #337aff;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      font-size: 14px;
      color: #999999;
    }
    .spec-list {
      display: flex;
    }
    .spec-item {
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      margin-right: 12px;
      border: 1px solid #337aff;
      border-radius: 3px;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
        color: #337aff;
      }
      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
  }
}
.single-one {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337aff;
    background: #ffffff;
    border: 1px solid #337aff;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      font-size: 14px;
      color: #999999;
    }
    .spec-list {
      display: flex;
    }
    .spec-item {
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      margin-right: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      color: #337aff;
      border: 1px solid #337aff;
    }
  }
}
.single-two {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337aff;
    background: #ffffff;
    border: 1px solid #337aff;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      font-size: 14px;
      color: #999999;
    }
    .spec-list {
      display: flex;
    }
    .spec-item {
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      margin-right: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      color: #337aff;
      border: 1px solid #337aff;
    }
  }
}
.label-enter-active,
.label-leave-active {
  transition: 0.2s;
}
.label-enter {
  transform: translate(-10px);
  opacity: 0;
}
.label-leave-to {
  transform: translate(10px);
  opacity: 0;
}

// .caijing {
.el-col {
  p {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .litter-title {
    color: #999;
  }
}
// }
.border{
  border-bottom: 1px dashed #e9e9e9;
  background: transparent;
  height: 0;
}
</style>